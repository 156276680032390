import FacebookIcon from 'images/components/socials/Facebook.svg'
import InstagramIcon from 'images/components/socials/Instagram.svg'
import WhatsAppIcon from 'images/components/socials/WhatsApp.svg'
import YoutubeIcon from 'images/components/socials/Youtube.svg'
import TwitterIcon from 'images/components/socials/Twitter.svg'

export const socials = [
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/ummatyfoundation/',
    icon: InstagramIcon,
  },
  {
    name: 'Facebook',
    href: 'http://facebook.com/ummaty.org',
    icon: FacebookIcon,
  },
  {
    name: 'WhatsApp',
    href: 'https://api.whatsapp.com/send?phone=13432624455',
    icon: WhatsAppIcon,
  },
  {
    name: 'Youtube',
    href: 'https://www.youtube.com/channel/UC22q9fNsNnnfqVlJMKW1PNw',
    icon: YoutubeIcon,
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/UmmatyGlobal',
    icon: TwitterIcon,
  },
]

type Column = {
  title: string
  items: Array<
    | {
        name: string
        link: {
          en: string
          fr: string
        }
      }
    | {name: string; href: string; pre?: string}
  >
}

export const columns: Column[] = [
  {
    title: 'Ummaty',
    items: [
      {
        name: 'About us',
        link: {
          en: '/about-us/',
          fr: '/fr/about-us/',
        },
      },
      {
        name: 'FAQ',
        link: {
          en: '/faq/',
          fr: '/fr/faq/',
        },
      },
    ],
  },
  // {
  //   title: 'Legal',
  //   items: [
  //     {
  //       name: 'Terms of Use',
  //       link: '/terms/',
  //     },
  //     {
  //       name: 'Privacy Policy',
  //       link: '/privacy/',
  //     },
  //   ],
  // },
  {
    title: 'contactUsTitle',
    items: [
      {
        name: 'contact@ummaty.org',
        href: 'mailto:contact@ummaty.org',
      },
      // {
      //   name: '+1 (343) 262-4455',
      //   href: 'https://api.whatsapp.com/send?phone=13432624455',
      //   pre: 'WhatsApp:',
      // },
    ],
  },
]
